
    import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
    import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
    import { faMapMarkerAlt as farMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
    import {
        faChevronRight as fasChevronRight,
        faSlidersSimple as fasSlidersSimple,
    } from '@fortawesome/pro-solid-svg-icons';
    import { faSlidersH as fasSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    import SaveSearch from '@/components/SaveSearch/SaveSearch';
    import C1TypeaheadSingleSelect from '@/C1Components/C1TypeaheadSingleSelect.vue';
    import C1KeywordSearch from '@/C1Components/C1KeywordSearchOptimised/C1KeywordSearch.vue';
    import C1DropdownFilter from '@/C1Components/C1DropdownFilter.vue';
    import SearchbarMixin from '@/components/Search/Searchbars/SearchbarMixin';

    export default {
        name: 'SearchbarDetailed',

        components: {
            SearchbarFilters: () => process.client ? import(/* webpackChunkName: "SearchbarFilters" */'@/components/Search/SearchbarFilters') : {},
            SearchTagAttributes: () => process.client ? import(/* webpackChunkName: "SearchTagAttributes" */'@/components/Search/Filters/SearchTagAttributes') : {},
            C1KeywordSearch,
            C1TypeaheadSingleSelect,
            SaveSearch,
            FontAwesomeIcon,
            C1DropdownFilter,
            SearchPayRange: () => process.client ? import(/* webpackChunkName: "SearchPayRange" */'@/components/Search/Filters/SearchPayRange') : {},
        },

        mixins: [SearchbarMixin],

        props: {
            exitUrl: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                icons: {
                    farSearch,
                    farTimes,
                    farMapMarkerAlt,
                    fasChevronRight,
                    fasSlidersH,
                    fasSlidersSimple,
                },
            };
        },
    };
