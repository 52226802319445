
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faTimes as fasTimes,
        faChevronDown as fasChevronDown,
        faChevronUp as fasChevronUp,
    } from '@fortawesome/pro-solid-svg-icons';

    import C1KeywordMixins from './C1KeywordMixins';

    export default {
        name: 'C1KeywordSearch',

        components: {
            FontAwesomeIcon,
            Checkbox: () => import('@/components/Utils/Checkbox'),
        },

        mixins: [C1KeywordMixins],

        data() {
            return {
                icons: {
                    fasTimes,
                    fasChevronDown,
                    fasChevronUp,
                },
            };
        },

        computed: {
            isSearchResultsOpen() {
                return this.isSearchOpen && (this.searchData.search && this.searchData.search.length);
            },
        },
    };
