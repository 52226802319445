
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faChevronDown as farChevronDown,
        faChevronUp as farChevronUp,
    } from '@fortawesome/pro-regular-svg-icons';

    export default {
        name: 'C1DropdownFilter',

        components: {
            FontAwesomeIcon,
        },

        props: {
            label: {
                type: String,
                default: '',
            },

            isActive: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isOpen: false,
                icons: {
                    farChevronDown,
                    farChevronUp,
                },
            };
        },

        methods: {
            toggle() {
                this.isOpen = !this.isOpen;

                if (this.isOpen) {
                    this.$emit('open');
                }
            },

            close() {
                this.isOpen = false;
            },
        },
    };
